<!-- 银盛钱包管理 -->
<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <label>酒店名称：</label>
          <el-input v-model="hotelName" placeholder="请输入酒店" class="width-250" clearable></el-input>
          <el-button class="bg-gradient m-left-20" type="primary" @click="getData"><span>搜索</span></el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true">
          <el-table-column type="index" width="100" label="序号"></el-table-column>
          <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
          <el-table-column label="钱包剩余金额">
            <template slot-scope="scope">￥{{ scope.row.accountAmount }}</template>
          </el-table-column>
          <el-table-column label="已提现金额">
            <template slot-scope="scope">￥{{ scope.row.cumulativelyWithdrawn }}</template>
          </el-table-column>
          <el-table-column prop="withdrawalTime" label="最近一次提现时间"></el-table-column>
          <el-table-column label="账户状态">
            <template slot-scope="scope">
              <div :style="scope.row.accountState === 'YES' ? { color: 'red' } : ''">
                {{ scope.row.accountState === 'YES' ? '锁定' : '正常' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <el-button type="text" @click="updateState(scope.row)">
                <el-link type="primary">{{ scope.row.accountState === 'YES' ? '恢复' : '锁定' }}</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </div>
</template>

<script>
  import { getWalletPage,updateAccountState } from '@/api/system/ysManagement'
  export default {
    data() {
      return {
        crumbs: new Map([['系统管理'], ['银盛钱包管理']]),
        hotelName: '',
        tableData: [],
        total: 0,                // 用户列表总条目数
        page: 1,                 // 当前页 默认第一页
        limit:10,                // 每页显示数
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        let data = {
          limit: this.limit,
          page: this.page,
        }
        if (this.hotelName) {
          data.hotelName = this.hotelName
        }
        getWalletPage(data).then(({total,success,records}) => {
          this.total = total
          if (success) {
            this.tableData = records
          }
        })
      },
      // 修改账户状态
      updateState(item){
        let data = {
          accountState: item.accountState === 'YES' ? 'NO' : 'YES',
          id: item.id
        }
        updateAccountState(data).then(({success,data})=>{
          if (success&&data) {
            this.$message({showClose: true,message: '操作成功!',type: 'success'});
            this.getData();
          }
        })
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num;
        this.getData();
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num;
        this.getData();
      },
    },
  }
</script>